<template>
    <div class="table-responsive">
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('id')" class="id-header-tb">
                    ID
                    <span v-if="sortKey === 'id'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('email')" class="id-header-tb">
                    Nombre de Usuario
                    <span v-if="sortKey === 'email'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('company.name')" class="id-header-tb">
                    Empresa
                    <span v-if="sortKey === 'company.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('name')" class="id-header-tb">
                    Nombre
                    <span v-if="sortKey === 'name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('lastName')" class="id-header-tb">
                    Apellido
                    <span v-if="sortKey === 'lastName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('jobTitle')" class="id-header-tb">
                    Puesto
                    <span v-if="sortKey === 'jobTitle'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('workArea')" class="id-header-tb">
                    Área
                    <span v-if="sortKey === 'workArea'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('phone')" class="id-header-tb">
                    Teléfono
                    <span v-if="sortKey === 'phone'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('remarks')" class="id-header-tb">
                    Observaciones
                    <span v-if="sortKey === 'remarks'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('status.name')" class="id-header-tb">
                    Estatus
                    <span v-if="sortKey === 'status.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th class="id-header-tb">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control"> {{ item.id }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.company.name }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.lastName }}</td>
                <td>{{ item.jobTitle }}</td>
                <td>{{ item.workArea }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.remarks }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton :disabled="isDisabled(item)" :class=" [(isDisabled(item)) ? 'btn-secondary':'btn-success'] " data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editUser(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="10" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
    </div>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getUsers() {
            return this.list;
        },
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {

                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);


                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editUser(user) {
            this.$emit('edit-user', user);
        },
        deleteUser(user) {
            this.$emit('delete-user', user);
        },
        isDisabled(item) {
            return item.status.name === "Baja";
        }
    }
}
</script>