<!-- Componente para los filtros (Seleccionar prospecto, Seleccionar folio cotización) de la búsqueda de cotización -->
<template>
  <div class="position-relative col-12">
    <div class="container-fluid p-0">
      <div class="row">
        <!-- Label para el select de prospectos -->
        <div class="col-12 col-md-3 col-lg-3 content-col">
          <label for="prospectSelect" class="mb-0 font-weight-lb">Consultar cotizaciones generadas:</label>
        </div>

        <!-- Select de prospectos -->
        <div class="col-12 col-md-4 col-lg-4">
          <select id="prospectSelect" v-model="selectedProspect" class="form-select selectpicker" data-width="100%"
            data-live-search="true" :disabled="!prospects.length" title="Selecciona prospecto...">
            <option value="" disabled>Selecciona prospecto</option>
            <option v-for="prospect in prospects" :key="prospect.prospectusId" :value="prospect.prospectusId">
              {{ prospect.generalInformation?.firstName || "" }}
              {{ prospect.generalInformation?.lastName || "" }}
              {{ prospect.generalInformation?.mothersMaidenName || "" }} -
              {{ prospect.branchOffice?.name || "" }}
            </option>
          </select>
        </div>

        <!-- Select de cotizaciones -->
        <div class="col-12 col-md-4 col-lg-4">
          <select id="quoteSelect" v-model="selectedQuote" class="form-select selectpicker" data-width="100%"
            data-live-search="true" :disabled="!selectedProspect || !quotes.length"
            title="Selecciona folio cotización...">
            <option value="" disabled>Selecciona folio cotización</option>
            <option v-for="quote in quotes" :key="quote.id" :value="quote.id">
              {{ quote.referenceNumber }}
            </option>
          </select>
        </div>

        <!-- Botón de búsqueda -->
        <div class="col-12 col-md-1 col-lg-1 pr-0">
          <PrimaryButton class="btn btn-primary w-100 space-btn" @click="search" :disabled="!selectedQuote">
            Buscar
          </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select";
import { ref, onMounted, computed, watch, nextTick } from "vue";
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useQuotationsStore } from '@/pinia/quotations.module';

export default {
  components: {
    PrimaryButton,
  },
  setup(_, { emit }) {
    const prospectStore = useIndividualProspectsStore();
    const quotationStore = useQuotationsStore();
    const selectedProspect = ref(null);
    const selectedQuote = ref(null);
    const quotes = ref([]);

    const initializeSelectPicker = () => {
      nextTick(() => {
        $(".selectpicker").selectpicker("refresh");
      });
    };

    const prospects = computed(() => {
      /* Paso 1: Obteniendo prospectos desde useIndividualProspectsStore */
      return prospectStore.getAllProspects || [];
    });

    watch(prospects, (newVal) => {
      if (newVal.length) {
        /* Paso 2: Ahora prospects tiene datos e inicializamos el componente select de prospectos */
        initializeSelectPicker();
      }
    }, { deep: true, immediate: true });

    watch(selectedProspect, async (newVal) => {
      if (newVal) {
        /* Paso 3: Prospecto seleccionado */
        try {
          await quotationStore.getQuotationsByProspectusId(newVal);
          const data = quotationStore.quotations;
          /* Paso 3.1: Cotizaciones obtenidas */
          quotes.value = Array.isArray(data) ? [...data] : [];
        } catch (error) {
          console.error("Error obteniendo cotizaciones", error);
          quotes.value = [];
        }
        /* inicializamos el componente select de cotizaciones */
        initializeSelectPicker();
      } else {
        quotes.value = [];
      }
    });

    const search = async () => {
      if(selectedQuote.value !== undefined){
        /* Paso 5: Emitir un evento al padre con los datos de la cotización seleccionada */
        emit("search", { prospect: selectedProspect.value, quote: selectedQuote.value });
      }
    };

    return {
      selectedProspect,
      selectedQuote,
      quotes,
      prospects,
      search
    };
  }
};
</script>

<style lang="css">
.content-col {
  align-content: center;
}

.font-weight-lb {
  font-weight: normal !important;
}

.space-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>