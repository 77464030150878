<template>
    <Form ref="individual" @submit="handleSubmit">
        <div class="modal-header">
            <h4 class="modal-title">
                {{ formTitle }}
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <!-- Select para escoger cuál de los dos tipos de PF se hará el registro -->
             <PFTypeSelect v-model="selectedPfType" name="'pfType'" :options="pfTypeOptions"></PFTypeSelect>

            <!-- I. DATOS GENERALES -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">I. DATOS GENERALES</h3>
                </template>
                <FieldGroup v-model="generalInformation.lastName" name="lastName" colClass="col-sm-4"
                    label="Apellido Paterno:" placeholder="Apellido Paterno" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.mothersMaidenName" name="mothersMaidenName" colClass="col-sm-4"
                    label="Apellido Materno:" placeholder="Apellido Materno" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.firstName" name="firstName" colClass="col-sm-4" label="Nombre(s):"
                    placeholder="Nombre(s)" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.dateOfBirth" name="dateOfBirth" colClass="col-sm-4"
                    label="Fecha de Nacimiento (DD/MM/AA):" type="date" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.birthState" name="birthState" colClass="col-sm-4"
                    label="Entidad Federativa:" placeholder="Entidad Federativa" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.birthCountry" name="birthCountry" colClass="col-sm-4"
                    label="País de Nacimiento:" placeholder="País de Nacimiento" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.nationality" name="nationality" colClass="col-sm-4"
                    label="Nacionalidad:" placeholder="Nacionalidad" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.gender" name="gender" colClass="col-sm-4" label="Género:"
                    placeholder="Género" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.maritalStatus" name="maritalStatus" colClass="col-sm-4"
                    label="Estado Civil:" placeholder="Estado Civil" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.maritalRegime" name="maritalRegime"
                    colClass="col-sm-4" label="Régimen Matrimonial:" placeholder="Régimen Matrimonial" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.spousesInformation" name="spousesInformation" colClass="col-sm-4"
                    label="Datos del Cónyuge (Apellido Paterno, Apellido Materno, Nombres):"
                    placeholder="Datos del Cónyuge" rules="max:300">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.mainActivity" name="mainActivity" colClass="col-sm-4"
                    label="Actividad, Ocupación, Profesión o Giro del Negocio:"
                    placeholder="Actividad, Ocupación, Profesión o Giro del Negocio" rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.curp" name="curp" colClass="col-sm-4" label="CURP:"
                    placeholder="CURP" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.rfc" name="rfc" colClass="col-sm-4" label="RFC o TAX ID:"
                    placeholder="RFC o TAX ID" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.identificationType" name="identificationType"
                    colClass="col-sm-4" label="Nombre de la Identificación:" placeholder="Nombre de la Identificación" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.issuingAuthority" name="issuingAuthority" colClass="col-sm-4"
                    label="Autoridad que la Emite:" placeholder="Autoridad que la Emite" rules="required|max:255">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.identificationNumber" name="identificationNumber"
                    colClass="col-sm-4" label="No. de Identificación:" placeholder="No. de Identificación" :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- II. DOMICILIO DEL LUGAR DE RESIDENCIA -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">II. DOMICILIO DEL LUGAR DE RESIDENCIA</h3>
                </template>
                <FieldGroup v-model="homeAddress.street" name="street" colClass="col-sm-4"
                    label="Calle / Avenida:" placeholder="Calle / Avenida" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.exteriorNumber" name="exteriorNumber" colClass="col-sm-2" label="No. Ext.:"
                    placeholder="No. Ext." :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.interiorNumber" name="interiorNumber" colClass="col-sm-2" label="No. Int.:"
                    placeholder="No. Int." :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.district" name="district" colClass="col-sm-4"
                    label="Colonia / Urbanización:" placeholder="Colonia / Urbanización" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.municipality" name="municipality" colClass="col-sm-4"
                    label="Delegación / Municipio:" placeholder="Delegación / Municipio" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.city" name="city" colClass="col-sm-4"
                    label="Ciudad / Población:" placeholder="Ciudad / Población" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.state" name="state"
                    colClass="col-sm-4" label="Entidad Federativa:" placeholder="Entidad Federativa" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.postalCode" name="postalCode" colClass="col-sm-4"
                    label="Código Postal:" placeholder="Código Postal" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.country" name="country" colClass="col-sm-4"
                    label="País:" placeholder="País" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="homeAddress.references" as="textarea"
                    name="references" colClass="col-sm-12"
                    label="Referencias de Ubicación del Domicilio:"
                    placeholder="Referencias de Ubicación del Domicilio" :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- III. DOMICILIO EN TERRITORIO NACIONAL CUANDO EL LUGAR DE RESIDENCIA SEA EN EL EXTRANJERO -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">III. DOMICILIO EN TERRITORIO NACIONAL CUANDO EL LUGAR DE RESIDENCIA SEA EN EL EXTRANJERO</h3>
                </template>
                <FieldGroup v-model="nationalAddress.street" name="street2" colClass="col-sm-4"
                    label="Calle / Avenida:" placeholder="Calle / Avenida" rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.exteriorNumber" name="exteriorNumber2" colClass="col-sm-2"
                    label="No. Ext.:" placeholder="No. Ext." rules="max:10">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.interiorNumber" name="interiorNumber2" colClass="col-sm-2"
                    label="No. Int.:" placeholder="No. Int." rules="max:10">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.district" name="district2"
                    colClass="col-sm-4" label="Colonia / Urbanización:" placeholder="Colonia / Urbanización"
                    rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.municipality" name="municipality2"
                    colClass="col-sm-4" label="Delegación / Municipio:" placeholder="Delegación / Municipio"
                    rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.city" name="city2" colClass="col-sm-4"
                    label="Ciudad / Población:" placeholder="Ciudad / Población" rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.state" name="state2"
                    colClass="col-sm-4" label="Entidad Federativa:" placeholder="Entidad Federativa" rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.postalCode" name="postalCode2" colClass="col-sm-4"
                    label="Código Postal:" placeholder="Código Postal" rules="max:20">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.country" name="country2" colClass="col-sm-4" label="País:"
                    placeholder="País" rules="max:255">
                </FieldGroup>
                <FieldGroup v-model="nationalAddress.references" as="textarea"
                    name="references2" colClass="col-sm-12"
                    label="Referencias de Ubicación del Domicilio:"
                    placeholder="Referencias de Ubicación del Domicilio" rules="max:500">
                </FieldGroup>
            </Card>

            <!-- IV. DATOS DE LOCALIZACIÓN -->
            <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">IV. DATOS DE LOCALIZACIÓN</h3>
                    <!-- <div class="row col-sm-4">
                        <label>¿Es extranjero visitante?</label>
                        <input type="checkbox" class="ml-2" v-model="selectedPfType" />
                    </div> -->
                </template>
                <FieldGroup v-if="selectedPfType === 2" v-model="generalInformation.country" name="country" colClass="col-sm-3"
                    label="País:" placeholder="País" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.homePhone" name="homePhone" colClass="col-sm-3"
                    label="Teléfono Domicilio:" placeholder="Teléfono Domicilio" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-if="selectedPfType === 1" v-model="generalInformation.officePhone" name="officePhone" colClass="col-sm-3"
                    label="Teléfono Trabajo:" placeholder="Teléfono Trabajo" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-if="selectedPfType === 1" v-model="generalInformation.cellPhone" name="cellPhone" colClass="col-sm-3"
                    label="Teléfono Celular:" placeholder="Teléfono Celular" :disabled="notBeneficiary">
                </FieldGroup>
                <FieldGroup v-model="generalInformation.email" name="email" colClass="col-sm-3"
                    label="Correo Electrónico:" placeholder="Correo Electrónico" :disabled="notBeneficiary">
                </FieldGroup>
            </Card>

            <!-- Beneficiary Status -->
             <Card :customClass="cardClass">
                <template v-slot:header>
                    <h3 class="card-title">Estatus</h3>
                </template>
                <!-- Select para escoger el estatus del beneficiario -->
                <SelectField v-model="selectedStatus" name="'status'" :options="statusOptions" :isDisabled="notBeneficiary"></SelectField>
             </Card>

        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ submitButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import * as helper from '@/helpers';

import Card from '@/Components/Bootstrap/Card.vue';
import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import PFTypeSelect from '@/Components/Selects/SelectField.vue';
import SelectField from '@/Components/Selects/SelectField.vue';

import GeneralInformation from '@/Models/GeneralInformation';
import FiscalAddress from '@/Models/FiscalAddress';

export default {
    components: {
        Card,
        FieldGroup,
        PFTypeSelect,
        SelectField,
        PrimaryButton
    },
    props: {
        initialData: {
            type: Object,
            required: false,
            default: () => null,
        },
        fetchById: {
            type: Function,
            required: false,
            default: () => null,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        formTitle: {
            type: String,
            required: true,
        },
        submitButtonText: {
            type: String,
            required: true,
        },
        notBeneficiary: {
            type: Boolean,
            required: true,
        },
        statusValues: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            formData: null,
            cardClass: 'card-primary',
            selectedPfType: 0,
            selectedStatus: 0,
            PFTypes: [
                {
                    id: 1,
                    name: 'PERSONAS FÍSICAS Y QUE DECLAREN SER DE NACIONALIDAD MEXICANA O DE NACIONALIDAD EXTRANJERA CON LAS CONDICIONES DE RESIDENTE TEMPORAL O RESIDENTE PERMANENTE, EN TÉRMINOS DE LA LEY DE MIGRACIÓN.'
                },
                {
                    id: 2,
                    name: 'PERSONAS FÍSICAS EXTRANJERAS, CON LAS CONDICIONES DE ESTANCIA DE VISITANTE O DISTINTA A LAS ESTABLECIDAS EN LA FRACCIÓN I DEL ARTÍCULO 12 DE LAS REGLAS, EN TÉRMINOS DE LA LEY DE MIGRACIÓN.'
                },
            ],
            generalInformation: new GeneralInformation(),
            homeAddress: new FiscalAddress(),
            nationalAddress: new FiscalAddress(),
        }
    },
    computed: {
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel')
        },
        pfTypeOptions() {
            return helper.mapOptions(this.PFTypes);
        },
        statusOptions() {
            return helper.mapOptions(this.statusValues);
        },
    },
    watch: {
        initialData: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.referenceNumber != null && newValue.ReferenceNumber != "") {
                    this.selectedPfType = newValue.pfType;
                    this.selectedStatus = newValue.statusId;
                    this.generalInformation = newValue.generalInformation;
                    this.generalInformation.dateOfBirth = this.$filters.formatDate(this.generalInformation.dateOfBirth, 'yyyy-MM-dd');
                    this.homeAddress = newValue.homeAddress;
                    this.nationalAddress = newValue.nationalAddress ? newValue.nationalAddress : new FiscalAddress();
                }
            }
        }
    },
    methods: {
        handleSubmit() {
            this.formData = {
                pfType: this.selectedPfType,
                statusId: this.selectedStatus,
                generalInformation: this.generalInformation,
                homeAddress: this.homeAddress,
                nationalAddress: (this.nationalAddress.street == "" || this.nationalAddress.street == null) ? null : this.nationalAddress,
            }
            this.onSubmit(this.formData);
            this.$refs.individual.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeModal', true);
        },
    },
    created() {
        if (this.fetchById() != null) {
            this.fetchById();
        }
    },
}
</script>