<template>
    <div class="d-flex justify-content-between">
        <h3 class="card-title">Lista de Cuestionarios</h3>
        <div class="d-flex">
            <PrimaryButton class="btn btn-primary mr-3" data-toggle="modal" :data-target="modalDataTarget"
                @click="openLoanApplicationTable">
                Crear Cuestionario
            </PrimaryButton>
        </div>
    </div>

    <div class="d-flex mt-3 mb-3">
        <input type="text" class="form-control" placeholder="Buscar Cuestionarios..." v-model="searchString" />
    </div>

    <div class="position-relative col-sm-12">

        <QuestionnairesTable 
            :list="filteredQuestionnaire" 
            :itemsPerPage="itemsPerPage" 
            :currentPage="currentPage" 
            :modalDataTarget="modalDataTarget" 
            @editItem="openEditionForm"
        ></QuestionnairesTable>
        <Pagination 
            v-if="filteredQuestionnaire.length > itemsPerPage"
            :list="filteredQuestionnaire" 
            :itemsPerPage="itemsPerPage"
            :currentPage="currentPage"
            @current-page="currentPage = $event"
        ></Pagination>
        
        <Modal :modalId="modalId" :customClass="modalId">

            <div v-if="modalContent === 1">
                <!-- Agregar tabla de Solicitudes para escoger una -->
                 <LoanApplicationsMinTable :list="getMixedLoanApplications" @selected-item="setSelectedLoanApplication"></LoanApplicationsMinTable>
            </div>
            
            <!-- Agregar formulario de creación -->
             <AddQuestionnaireForm v-if="modalContent === 2 && editing === false" :loanApplicationId="this.selectedLoanApplication.id" @closeModal="modalStatus"></AddQuestionnaireForm>
            <!-- Agregar formulario de edición -->
            <AddQuestionnaireFormEdit v-if="modalContent === 3 && editing === true" :selectedItemId="selectedItem.id" @closeModal="modalStatus"></AddQuestionnaireFormEdit>

        </Modal>

    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useQuestionnairesStore } from '@/pinia/questionnaires.module';
import { useIndividualLoanApplicationStore } from '@/pinia/LoanApplications/individual.module';

import PrimaryButton from '@/Components/PrimaryButton.vue';
import Pagination from '@/Components/Bootstrap/Paginationv2.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import QuestionnairesTable from '@/Components/Tables/QuestionnairesTable.vue';
import LoanApplicationsMinTable from '@/Components/Tables/LoanApplicationsMinTable.vue';
import AddQuestionnaireForm from '@/Components/Forms/LoanApplications/PLD/QuestionaireDetails.vue';
import AddQuestionnaireFormEdit from '@/Components/Forms/LoanApplications/PLD/QuestionaireDetailsEdit.vue';

export default {
    components: {
        PrimaryButton,
        Pagination,
        Modal,
        QuestionnairesTable,
        LoanApplicationsMinTable,
        AddQuestionnaireForm,
        AddQuestionnaireFormEdit,
    },
    data() {
        return {
            modalId: 'modal-xl',
            modalDataTarget: '#modal-xl',
            itemsPerPage: 10,
            currentPage: 1,
            modalContent: 0,
            editing: false,
            selectedLoanApplication: {},
            selectedItem: {},
            searchString: '',
            totalRecords: 0
        }
    },
    computed: {
        ...mapState(useQuestionnairesStore, ['getQuestionnaires', 'filterQuestionnaireBySearchString']),
        ...mapState(useIndividualLoanApplicationStore, ['getMixedLoanApplications']),
        filteredQuestionnaire() {
            return this.filterQuestionnaireBySearchString(this.searchString);
        }
    },
    methods: {
        ...mapActions(useQuestionnairesStore, ['fetchQuestionnaires']),
        ...mapActions(useIndividualLoanApplicationStore, ['fetchMixedLoanApplications']),
        modalStatus(status) {
            if (status) {
                $(this.modalDataTarget).modal('hide');
                this.modalContent = 0;
                this.editing = false;
            }
        },
        openLoanApplicationTable() {
            this.modalContent = 1;
        },
        openEditionForm(item) {
            this.selectedItem = item;
            this.modalContent = 3;
            this.editing = true;
        },
        setSelectedLoanApplication(item) {
            this.selectedLoanApplication = item;
            this.modalContent = 2;
        },
    },
    created() {
        this.fetchQuestionnaires();
        this.fetchMixedLoanApplications();
    },
    mounted() {
        $(this.modalDataTarget).on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
        });
    }
}
</script>