import { defineStore } from 'pinia';
import {useToast} from 'vue-toast-notification';
import {useLoadingStore} from '@/pinia/loading.module';
import {useAuthStore} from '@/pinia/auth.module';
import {getLocaleMessage} from '@/services/MessagesService';
import * as service from '@/services';

import Questionnaire from '@/Models/Questionnaires/Questionnaire';
import Question from '@/Models/Questionnaires/QuestionnaireQuestion.js';

const $toast = useToast();

export const useQuestionnairesStore = defineStore('questionnaire', {
    persist: false,
    state: () => {
        return {
            questionnaires: [],
            questionnaire: new Questionnaire(),
            questions: [],
            errors: [],
        }
    },
    getters: {
        getQuestionnaires: state => state.questionnaires,
        getQuestionnaire: state => state.questionnaire,
        getQuestions: state => state.questions,
        filterQuestionnaireBySearchString: state => (searchString = '') => {
            if (!searchString) return state.questionnaires;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.questionnaires.filter(question => {
                const code = question.referenceNumber ? question.referenceNumber.toLowerCase() : '';
                const codeLoanApplication = question.loanApplication?.referenceNumber ? question.loanApplication?.referenceNumber.toLowerCase() : '';
                const fullName = question.intervieweeFullName ? question.intervieweeFullName.toLowerCase() : '';
                return code.includes(lowerCaseSearchString) ||
                    codeLoanApplication.includes(lowerCaseSearchString) ||
                    fullName.includes(lowerCaseSearchString);
            });
        }
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchQuestionnaires() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.questionnairesApi.get(`/api/Questionnaires/Company/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    const questionnaires = data.data;
                    this.questionnaires = questionnaires.map(questionnaire => new Questionnaire(questionnaire));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchQuestionnaireById(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.questionnairesApi.get(`/api/Questionnaires/${id}`);
                if (data && data.status) {
                    const questionnaire = data.data;
                    this.questionnaire = new Questionnaire(questionnaire);
                    this.questions = this.questionnaire.questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        
        async fetchQuestions() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.questionnairesApi.get(`/api/Questions`);
                if (data && data.status) {
                    const questions = data.data;
                    this.questions = questions.map(q => new Question(q));
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createQuestionnaire(questionnaire) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.questionnairesApi.post(`/api/Questionnaires`, questionnaire);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('questionnaires', 'creatingSuccess'));
                    const questionnaire = data.data;
                    const newQuestionnaire = new Questionnaire(questionnaire);
                    this.questionnaires.unshift(newQuestionnaire);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateQuestionnaire(questionnaire) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.questionnairesApi.put(`/api/Questionnaires`, questionnaire);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('questionnaires', 'editingSuccess'));
                    const updatedQuestionnaire = data.data;
                    const index = this.questionnaires.findIndex(c => c.id === updatedQuestionnaire.id);
                    if (index !== -1) {
                        this.questionnaires.splice(index, 1, updatedQuestionnaire);
                        this.questionnaires.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
    }
});