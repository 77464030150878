import { defineStore } from 'pinia';

import { getLocaleMessage } from '@/services/MessagesService';

import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useHandlerStore } from '@/pinia/handler.module';
import { useAuthStore } from '@/pinia/auth.module';

import User from '../Models/User';

import * as service from '@/services';

const $toast = useToast();

export const useUsersStore = defineStore('users', {
    persist: false,
    state: () => {
        return {
            users: [],
            user: new User(),
            activeUsers: [],
            activeUser: new User(),
            paginatedUsers: [],
            errors: [],
        }
    },
    getters: {
        getSelectedUser: state => state.user,
        // TODO: revisar que el password no se envíe al front
        getUsers: state => state.users,
        getUserById: state => id => {
            return state.users.find(c => c.id === id);
        },

        getActiveUsers: state => {
            let activeUsers = state.activeUsers.filter(c => c.statusId === 2);
            return activeUsers;
        },
        getSelectedActiveUser: state => state.activeUser,
        getActiveUserById: state => id => {
            if (id === 0 || id === null) return {};
            return state.activeUsers.find(c => c.id === id);
        },
        getPaginatedUsers: state => state.paginatedUsers,
        filterUserBySearchString: state => (searchString = '') => {
            if (!searchString) return state.users;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.users.filter(user => {
                const code = user.id ? user.id.toString() : '';
                const email = user.email ? user.email.toLowerCase() : '';
                const nameCompany = user.company?.name ? user.company?.name.toLowerCase() : '';
                const nameUser = user.name ? user.name.toLowerCase() : '';
                const lastnameUser = user.lastName ? user.lastName.toLowerCase() : '';
                const job = user.jobTitle ? user.jobTitle.toLowerCase() : '';
                const area = user.workArea ? user.workArea.toLowerCase() : '';
                const phone = user.phone ? user.phone : '';
                const remark = user.remarks ? user.remarks.toLowerCase() : '';
                const status = user.status?.name ? user.status?.name.toLowerCase() : '';

                return code.includes(lowerCaseSearchString) ||
                    email.includes(lowerCaseSearchString) ||
                    nameCompany.includes(lowerCaseSearchString) ||
                    nameUser.includes(lowerCaseSearchString) ||
                    lastnameUser.includes(lowerCaseSearchString) ||
                    job.includes(lowerCaseSearchString) ||
                    area.includes(lowerCaseSearchString) ||
                    phone.includes(lowerCaseSearchString) ||
                    remark.includes(lowerCaseSearchString) ||
                    status.includes(lowerCaseSearchString);
            });
        }
    },
    actions: {
        SETSELECTEDUSER(user) { this.user = user; },
        setPaginatedUsers(users) { this.paginatedUsers = users; },
        updatePaginatedUser(user) {
            const index = this.paginatedUsers.findIndex(c => c.id === user.id);
            if (index !== -1) {
                this.paginatedUsers.splice(index, 1, user);
            }
        },
        setErrors(errors) {
            this.errors.push(errors);
        },
        async fetchUsers() {
            try {
                const { data } = await service.profilesApi.get(`/api/users/company/${useAuthStore().user.companyId}`);
                if (data) {
                    const users = data.map((userData) => new User(userData));
                    this.users = users;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchActiveUsersByCompany() {
            try {
                console.log(useAuthStore().user.companyId);
                const { data } = await service.profilesApi.get(`/api/users/company/${useAuthStore().user.companyId}/active`);
                if (data) {
                    const users = data.map((userData) => new User(userData));
                    this.activeUsers = users;
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createUser(user) {
            try {
                const { data } = await service.profilesApi.post(`/api/users`, user);
                if (data) {
                    $toast.success(getLocaleMessage('users', 'creatingSuccess'));
                    const newUser = new User(data);
                    this.users.unshift(newUser);
                }
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateUser(user) {
            try {
                const { data: data } = await service.profilesApi.put(`/api/users/${user.id}`, user);
                if (data) {
                    $toast.success(getLocaleMessage('users', 'editingSuccess'));
                    const newUser = new User(data);
                    const index = this.users.findIndex(u => u.id === newUser.id);
                    if (index !== -1) {
                        this.users.splice(index, 1, newUser);
                        this.users.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1);
                    }
                }
            } catch (error) {
                useLoadingStore().loading = false;
                useHandlerStore().setErrors(error);
                $toast.error(getLocaleMessage('common', 'networkError'));
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async deleteUser(id) {
            try {
                const { data: data } = await service.profilesApi.delete(`/api/users/${id}`);
                $toast.success(getLocaleMessage('users', 'deletingSuccess'));
                const index = this.users.findIndex(c => c.id === id);
                if (index !== -1) {
                    this.users.splice(index, 1);
                }
                return data;
            } catch (error) {
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    this.setErrors(error);
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            }
        },
    },
});