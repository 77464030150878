<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">Configuración de Usuarios</h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Usuarios</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <div class="d-flex justify-content-between">
                                <h3 class="card-title">Lista de Usuarios</h3>
                                <PrimaryButton class="btn btn-primary" data-toggle="modal" data-target="#modal-lg" @click="openCreationForm">
                                    {{ $getLocaleMessage('buttons', 'addNewRecord') }}
                                </PrimaryButton>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Buscar Usuario..."
                                    v-model="searchString" />
                            </div>
                            <!-- /.d-flex -->

                            <div class="position-relative col-sm-12">
                                <UsersTable 
                                    :list="filteredUser" 
                                    :itemsPerPage="itemsPerPage" 
                                    :currentPage="currentPage" 
                                    @edit-item="openEditionForm"
                                    @delete-user="deleteRow"
                                >
                                </UsersTable>

                                <Pagination
                                    v-if="filteredUser.length > itemsPerPage" 
                                    :list="filteredUser" 
                                    :itemsPerPage="itemsPerPage" 
                                    :currentPage="currentPage" 
                                    @current-page="currentPage = $event">
                                </Pagination>

                                <Modal>
                                    <AddUserForm v-if="modalContent === 1 && editing === false" 
                                    @closeModal="modalStatus"></AddUserForm>
                                    <EditUserForm v-if="modalContent === 2 && editing === true" 
                                    :selected-record="selectedRecord" 
                                    @closeModal="modalStatus"></EditUserForm>
                                </Modal>

                            </div>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import '@/Components/Validators/index';
import Swal from 'sweetalert2';

import { mapState, mapActions, } from 'pinia';
import { useUsersStore } from '@/pinia/users.module';
import { useCompaniesStore } from '@/pinia/companies.module';
import { useRolesStore } from '@/pinia/roles.module';
import { useStatusStore } from '@/pinia/status.module';

import UsersTable from '@/Components/Tables/UsersTable.vue';
import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import AddUserForm from '@/Components/Forms/Users/Add.vue';
import EditUserForm from '@/Components/Forms/Users/Edit.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: {
        UsersTable,
        Pagination,
        Modal,
        AddUserForm,
        EditUserForm,
        PrimaryButton,
    },
    mixins: [],
    data() {
        return {
            selectedRecord: {},
            itemsPerPage: 7,
            currentPage: 1,
            modalContent: 0,
            editing: false,
            searchString: '',
            totalRecords: 0,
        }
    },
    watch: {},
    computed: {
        ...mapState(useUsersStore, ['getUsers', 'getPaginatedUsers', 'filterUserBySearchString']),
        filteredUser() {
            return this.filterUserBySearchString(this.searchString);
        },
    },
    methods: {
        ...mapActions(useUsersStore, ['fetchUsers', 'deleteUser']),
        ...mapActions(useCompaniesStore, ['fetchCompanies']),
        ...mapActions(useRolesStore, ['fetchRoles']),
        ...mapActions(useStatusStore, ['fetchStatus']),
        deleteRow(user) {
            const alert = Swal.mixin({
                buttonsStyling: true
            });
            alert.fire({
                title: `Desea eliminar el usuario ${user.name}?`,
                icon: 'question', showCancelButton: true,
                confirmButtonText: '<i class="fa fa-solid fa-check"></i> Si, eliminar',
                cancelButtonText: '<i class="fa fa-solid fa-ban"></i> Cancelar',
            }).then(res => {
                if (res.isConfirmed) {
                    this.deleteUser(user.id);
                }
            });

        },
        modalStatus() {
            $('#modal-lg').modal('hide');
        },
        openCreationForm() {
            this.modalContent = 1;
            this.editing = false;
        },
        openEditionForm(selectedItem) {
            this.selectedRecord = { ...selectedItem }; // Clonar profundamente el objeto reactivo
            this.modalContent = 2;
            this.editing = true;
        },
    },
    created() {
        this.fetchUsers();
        this.fetchCompanies();
        this.fetchRoles();
        this.fetchStatus();
    },
    mounted() {
        $('#modal-lg').on('hidden.bs.modal', () => {
            this.modalContent = 0;
            this.editing = false;
        });
    }
};
</script>