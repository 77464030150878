<template>
    <div class="table-responsive">
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('referenceNumber')">
                    Folio de Prospectos
                    <span v-if="sortKey === 'referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('branchOffice.name')">
                    Matriz y/o Sucursales
                    <span v-if="sortKey === 'branchOffice.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('personType.name')">
                    Tipo de Persona
                    <span v-if="sortKey === 'personType.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('generalInformation.firstName')">
                    Nombre(s)
                    <span v-if="sortKey === 'generalInformation.firstName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('generalInformation.email')">
                    Correo electrónico
                    <span v-if="sortKey === 'generalInformation.email'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('generalInformation.officePhone')">
                    Teléfono
                    <span v-if="sortKey === 'generalInformation.officePhone'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('fiscalAddress.postalCode')">
                    Código Postal
                    <span v-if="sortKey === 'fiscalAddress.postalCode'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('status.name')" class="id-header-tb">
                    Estatus
                    <span v-if="sortKey === 'status.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                <td>{{ item.branchOffice.name }}</td>
                <td>{{ item.personType.name }}</td>
                <td>{{ getName(item) }}</td>
                <td>{{ item.generalInformation.email }}</td>
                <td>{{ getPhone(item) }}</td>
                <td>{{ getAddress(item) }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-xl"
                        @click.prevent="editProspect(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="11" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
    </div>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getProspects() {
            return this.list;
        },
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {

                    let aValue, bValue;

                    /**Sección personalizada */
                    if (this.sortKey === "generalInformation.firstName") {
                        aValue = a.generalInformation.businessName === null ? this.getNestedValue(a, this.sortKey) : this.getNestedValue(a, "generalInformation.businessName");
                        bValue = b.generalInformation.businessName === null ? this.getNestedValue(b, this.sortKey) : this.getNestedValue(b, "generalInformation.businessName");
                    } else if (this.sortKey === "generalInformation.officePhone") {
                        aValue = a.generalInformation.cellPhone === null ? this.getNestedValue(a, this.sortKey) : this.getNestedValue(a, "generalInformation.cellPhone");
                        bValue = b.generalInformation.cellPhone === null ? this.getNestedValue(b, this.sortKey) : this.getNestedValue(b, "generalInformation.cellPhone");
                    } else if (this.sortKey === "fiscalAddress.postalCode") {
                        aValue = a.homeAddress === null ? this.getNestedValue(a, this.sortKey) : this.getNestedValue(a, "homeAddress.postalCode");
                        bValue = b.homeAddress === null ? this.getNestedValue(b, this.sortKey) : this.getNestedValue(b, "homeAddress.postalCode");
                    } /**End: Sección personalizada */
                    else {
                        aValue = this.getNestedValue(a, this.sortKey);
                        bValue = this.getNestedValue(b, this.sortKey);
                    }


                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editProspect(prospect) {
            this.$emit('edit-prospect', prospect);
        },
        getName(item) {
            if (item.generalInformation.businessName != null) {
                return item.generalInformation.businessName;
            } else {
                return `${item.generalInformation.firstName} ${item.generalInformation.lastName} ${item.generalInformation.mothersMaidenName}`;
            }
        },
        getPhone(item) {
            if (item.personTypeId == 1) {
                return item.generalInformation.cellPhone;
            } else {
                return item.generalInformation.officePhone;
            }
        },
        getAddress(item) {
            if (item.personTypeId == 1) {
                return item.homeAddress.postalCode;
            } else {
                return item.fiscalAddress.postalCode
            }
        },
    }
}
</script>