<template>
    <Form ref="bureauInquiry" @submit="handleSubmit">
        <div class="modal-header">
            <h5 class="modal-title">
                {{ formTitle }} solicitud de consulta de buró de crédito
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <FieldGroup v-model="form.placeIssuance" name="placeIssuance" colClass="col-sm-12 col-md-6" label="Lugar de emisión:"
                    placeholder="Lugar de emisión" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.dateIssuance" type="date" name="dateIssuance" colClass="col-sm-12 col-md-6"
                    label="Fecha de emisión:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.signedAddress" name="signedAddress"
                    colClass="col-sm-12 col-md-6" label="Lugar en que se firma la autorización:" placeholder="Lugar en que se firma la autorización" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.signedDate" type="date" name="signedDate" colClass="col-sm-12 col-md-6"
                    label="Fecha  en que se firma la autorización:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.officerFullName" name="officerFullName"
                    colClass="col-sm-12 col-md-6" label="Nombre del funcionario que recaba la autorización:" placeholder="Nombre del funcionario que recaba la autorización"
                    rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.personAuthorizingFullName" name="personAuthorizingFullName"
                    colClass="col-sm-12 col-md-6" label="Nombre de la persona que autoriza:" placeholder="Nombre de la persona que autoriza:" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.companyName" name="companyName"
                    colClass="col-sm-12 col-md-6" label="Para uso exclusivo de la Empresa que efectúa la consulta:"
                    placeholder="Nombre de la empresa" rules="required">
                </FieldGroup>
                <FieldGroup v-model="form.inquiryDate" type="date" name="inquiryDate"
                    colClass="col-sm-12 col-md-6" label="Fecha de Consulta BC:" rules="required">
                </FieldGroup>
                <FieldGroup v-if="editing" v-model="form.referenceNumber" name="referenceNumber"
                    colClass="col-sm-12 col-md-6" label="Folio de Consulta BC:" rules="required" :disabled="true">
                </FieldGroup>
                <SelectField v-model="form.statusId" name="'status'" :options="statusOptions"></SelectField>
            </div>
        </div>
        <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ cancelButtonText }}</button>
            <PrimaryButton class="btn btn-primary" type="submit">
                {{ submitButtonText }}
            </PrimaryButton>
        </div>
    </Form>
</template>

<script>
import FieldGroup from '@/Components/Fields/FieldGroup.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SelectField from '@/Components/Selects/SelectField.vue';

import * as helper from '@/helpers';

export default {
    components: {
        FieldGroup,
        PrimaryButton,
        SelectField,
    },
    props: {
        initialData: {
            type: Object,
            required: false,
        },
        fetchById: {
            type: Function,
            required: false,
            default: () => null,
        },
        onSubmit: {
            type: Function,
            required: true,
        },
        formTitle: {
            type: String,
            required: true,
        },
        submitButtonText: {
            type: String,
            required: true,
        },
        statusValues: {
            type: Array,
            required: false,
            default: () => [],
        },
        editing: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            form: {},
            selectedStatus: 0,
        }
    },
    emmits: ['close-modal'],
    computed: {
        cancelButtonText() {
            return this.$getLocaleMessage('buttons', 'cancel');
        },
        statusOptions() {
            return helper.mapOptions(this.statusValues);
        },
    },
    methods: {
        handleSubmit() {
            this.onSubmit(this.form);
            this.$refs.bureauInquiry.resetForm();
            this.closeModal();
        },
        closeModal() {
            this.$emit('close-modal', true);
        },
    },
    watch: {
        initialData: {
            immediate: true,
            handler(newValue) {
                if (newValue && newValue.referenceNumber != null && newValue.ReferenceNumber != "") {
                    this.form = newValue;
                    this.form.dateIssuance = this.$filters.formatDate(newValue.dateIssuance, 'yyyy-MM-dd');
                    this.form.signedDate = this.$filters.formatDate(newValue.signedDate, 'yyyy-MM-dd');
                    this.form.inquiryDate = this.$filters.formatDate(newValue.inquiryDate, 'yyyy-MM-dd');
                }
            }
        }
    },
    created() {
        if (this.fetchById != null) {
            this.fetchById();
        }
    }
}
</script>