<template>
    <div class="table-responsive">
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('referenceNumber')">
                    Folio de Solicitudes
                    <span v-if="sortKey === 'referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('quotation.referenceNumber')">
                    Folio de Cotizaciones
                    <span v-if="sortKey === 'quotation.referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('prospectus.referenceNumber')">
                    Folio del Prospectos
                    <span v-if="sortKey === 'prospectus.referenceNumber'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('prospectus.branchOffice.name')">
                    Matriz y/o Sucursales
                    <span v-if="sortKey === 'prospectus.branchOffice.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('applicantsName')">
                    Solicitante
                    <span v-if="sortKey === 'applicantsName'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('applicationDate')">
                    Fecha de solicitud
                    <span v-if="sortKey === 'applicationDate'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('prospectus.personType.name')">
                    Tipo de persona
                    <span v-if="sortKey === 'prospectus.personType.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('status.name')">
                    Estatus
                    <span v-if="sortKey === 'status.name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control"> {{ item.referenceNumber }}</td>
                <td>{{ item.quotation.referenceNumber }}</td>
                <td>{{ item.prospectus.referenceNumber }}</td>
                <td>{{ item.prospectus.branchOffice.name }}</td>
                <td>{{ item.applicantsName }}</td>
                <td>{{ $filters.formatDate(item.applicationDate) }}</td>
                <td>{{ item.prospectus.personType.name }}</td>
                <td>{{ item.status.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" :data-target="dataTarget"
                        @click.prevent="editLoanApplication(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="9" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
    </div>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    emits: ['edit-loan-application'],
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        dataTarget: {
            type: String,
            default: '#modal-lg'
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getLoanApplications() {
            return this.list;
        },
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {

                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);


                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editLoanApplication(loanApplication) {
            this.$emit('edit-loan-application', loanApplication);
        },
    }
}
</script>