<template>
    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-12 col-md-6">
                    <h1 class="m-0">Cotizaciones</h1>
                </div><!-- /.col -->
                <div class="col-12 col-md-6">
                    <ol class="breadcrumb float-md-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'home' }">
                                Home
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active">Cotizaciones</li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header border-0">
                            <!-- Fila para el título -->
                            <div class="row mb-3">
                                <div class="col-12">
                                    <h3 class="card-title">Cotizaciones</h3>
                                </div>
                            </div>

                            <!-- Fila para el buscador y botones -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex flex-column flex-md-row">
                                        <!-- Contenedor para el buscador y el botón -->
                                        <div class="container-fluid pl-0">
                                            <div class="row g-3 align-items-center">
                                                <!-- Columna para el buscador -->
                                                <div class="col-12 col-md-10 pl-0">
                                                    <QuotationSelects @search="handleSearch" class="w-100" />
                                                </div>
                                                <!-- Columna para el botón -->
                                                <div class="col-12 col-md-2">
                                                    <PrimaryButton class="btn btn-primary w-100" data-toggle="modal"
                                                        data-target="#modal-lg" @click="contentNumber = 1">
                                                        Generar Nueva Cotización
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <!-- Resto del contenido -->
                            <div class="row">
                                <div class="col-12">
                                    <ul class="list-unstyled">
                                        <li><strong>Folio Cotización:</strong> {{ getQuotation.referenceNumber }}</li>
                                        <li><strong>Tipo de crédito:</strong> {{ this.productType }}</li>
                                        <li><strong>Nombre del prospecto:</strong> {{ this.prospectusFullName }}</li>
                                        <li><strong>Fecha de disposición del crédito:</strong> {{
                                            disburmentDateFormat(getQuotation.disbursementDate) }}</li>
                                        <li><strong>Monto del préstamo:</strong> {{
                                            pesosFormat(getQuotation.creditAmount) }}</li>
                                        <li><strong>Plazo total:</strong> {{ getQuotation.totalTerm }}</li>
                                        <li><strong>Período de gracia:</strong> {{ getQuotation.gracePeriod }}</li>
                                        <li><strong>Plazo:</strong> {{ getTotalTermWithGracePeriod }}</li>
                                        <li><strong>Tasa Anual sin IVA %:</strong> {{
                                            decimalToPercentFormat(getQuotation.annualRate) }}</li>
                                        <li><strong>Tasa Anual con IVA %:</strong> {{
                                            decimalToPercentFormat(getQuotation.annualRateWithVat) }}</li>
                                        <li><strong>Tasa {{ period }} sin IVA %:</strong> {{
                                            decimalToPercentFormat(getQuotation.periodRate) }}</li>
                                        <li><strong>Tasa {{ period }} con IVA %:</strong> {{
                                            decimalToPercentFormat(getQuotation.periodRateWithVat) }}</li>
                                        <li><strong>Pago {{ period }}:</strong> {{
                                            pesosFormat(getTotalInstallment(getQuotation.gracePeriod)) }}</li>
                                        <li><strong>Comisión por apertura %:</strong> {{
                                            decimalToPercentFormat(getQuotation.openingCommission) }}</li>
                                        <li><strong>CAT con IVA %:</strong> {{
                                            decimalToPercentFormat(getQuotation.aprWithVat) }}</li>
                                        <li><strong>CAT sin IVA %:</strong> {{ decimalToPercentFormat(getQuotation.apr)
                                            }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <QuotationDetails :list="loanAmortization" :totals="getQuotation.amortizationTotal"
                                        :referenceNumber="getQuotation.referenceNumber">
                                    </QuotationDetails>
                                </div>
                            </div>

                            <Modal>
                                <AddQuotationForm v-if="contentNumber == 1" @changeModalContent="setContentNumber"
                                    :prospectus="selectedProspectus" :product="selectedProduct"
                                    @prodType="productType = $event" @periodName="period = $event"
                                    @prospectusFullName="prospectusFullName = $event" @closeModal="modalStatus">
                                </AddQuotationForm>

                                <div v-else-if="contentNumber == 2">
                                    <ProspectsTable @back-to-form="backToForm($event)"
                                        @selectedProspectus="selectedProspectus = $event" :list="getAllProspects"
                                        :itemsPerPage="itemsPerPage" :currentPage="currentPage">
                                    </ProspectsTable>
                                    <Pagination :list="getAllProspects" :itemsPerPage="itemsPerPage"
                                        :currentPage="currentPage" @current-page="currentPage = $event">
                                    </Pagination>
                                </div>

                                <div v-else-if="contentNumber == 3">
                                    <ProductsTable @back-to-form="backToForm($event)" @selectedItem="getSelectedProduct"
                                        :list="getActiveProducts" :itemsPerPage="itemsPerPage"
                                        :currentPage="currentPage">
                                    </ProductsTable>
                                    <Pagination :list="getActiveProducts" :itemsPerPage="itemsPerPage"
                                        :currentPage="currentPage" @current-page="currentPage = $event">
                                    </Pagination>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <!-- /.card -->
                </div>
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content -->
</template>

<script>
import '@/Components/Validators/index';
import { mapState, mapActions } from 'pinia';
import { useIndividualProspectsStore } from '@/pinia/Prospects/individual.module';
import { useTaxesStore } from '@/pinia/taxes.module';
import { useProductsStore } from '@/pinia/products.module';
import { useQuotationsStore } from '@/pinia/quotations.module';

import Product from '@/Models/Product';
import Prospectus from '@/Models/Prospects';

import Pagination from '@/Components/Bootstrap/Pagination.vue';
import Modal from '@/Components/Bootstrap/Modal.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import ProspectsTable from '@/Components/Tables/ProspectsMinTable.vue';
import ProductsTable from '@/Components/Tables/ProductsMinTable.vue';
import AddQuotationForm from '@/Components/Forms/Quotations/Add.vue';
import QuotationDetails from '@/Components/Tables/QuotationDetailsTable.vue';
import QuotationSelects from '@/Components/Selects/HistoryQuotationSelect.vue';

export default {
    components: {
        ProspectsTable,
        ProductsTable,
        Pagination,
        Modal,
        AddQuotationForm,
        PrimaryButton,
        QuotationDetails,
        QuotationSelects
    },
    data() {
        return {
            selectedRecord: {},
            selectedProspectus: new Prospectus({}),
            selectedProduct: new Product({}),
            productType: '',
            prospectusFullName: '',
            annualRateWithVat: '',
            period: 'periodo',
            contentNumber: 0,
            currentPage: 1,
            itemsPerPage: 10,
        }
    },
    computed: {
        ...mapState(useIndividualProspectsStore, ['getAllProspects']),
        ...mapState(useQuotationsStore, ['getProspects', 'getQuotation']),
        ...mapState(useProductsStore, ['getActiveProducts']),
        loanAmortization() {
            let value;
            try {
                value = this.getQuotation.loanAmortization;
            } catch (error) {
                value = [];
            }
            return value;
        },
        getTotalTermWithGracePeriod() {
            let value;
            try {
                value = this.getQuotation.totalTerm - this.getQuotation.gracePeriod;
            } catch (error) {
                value = null;
            }
            return value;
        },
    },
    methods: {
        ...mapActions(useIndividualProspectsStore, ['fetchActiveMixedProspects']),
        ...mapActions(useTaxesStore, ['fetchActiveTaxes']),
        ...mapActions(useProductsStore, ['fetchActiveProductsByBranchOfficeId', 'deleteProduct']),
        ...mapActions(useQuotationsStore, ['getHistoryQuotation']),
        modalStatus(status) {
            if (status) {
                $('#modal-lg').modal('hide');
            }
        },
        backToForm() {
            this.contentNumber = 1;
        },
        setContentNumber(number) {
            if (number == 2) {
                // When select a new Prospectus we must empty the Product state and make user select a new Product
                this.selectedProduct = {};
            }
            this.contentNumber = number;
        },
        getSelectedProduct(product) {
            this.selectedProduct = product;
        },
        getSelectedProspectus(p) {
            this.selectedProspectus = p;
            this.prospectus = p;
        },
        disburmentDateFormat(value) {
            return this.$filters.formatDate(value, "dd 'de' MMMM 'del' yyy");
        },
        pesosFormat(value) {
            return this.$filters.formatNumber(value);
        },
        decimalToPercentFormat(value) {
            return this.$filters.formatPercent(value);
        },
        getTotalInstallment(gracePeriod) {
            let value;
            try {
                // We select the first installment after grace period is the grace period + 1
                // Seleccionamos el primer pago después del periodo de gracia es el periodo de gracia + 1
                value = this.getQuotation.loanAmortization[gracePeriod + 1].totalInstallment;
            } catch (error) {
                value = 0;
            }
            return value;
        },
        async handleSearch({ prospect, quote }) {
            if(quote !== undefined ){
                /* Paso 6: Búsqueda ejecutada */
                // Obtener la cotización seleccionada desde el store
                await this.getHistoryQuotation({ prospect, quote });

                // Actualizar loanAmortization y otros datos relacionados
                this.$forceUpdate(); // Forzar la actualización del componente si es necesario
            }
        }
    },
    watch: {
        selectedProspectus(p) {
            if (p?.branchOfficeId) {
                this.fetchActiveProductsByBranchOfficeId(p.branchOfficeId);
                this.fetchActiveTaxes(p.branchOfficeId);
            }
        }
    },
    created() {
        this.fetchActiveMixedProspects();
    },
    mounted() { }
}
</script>