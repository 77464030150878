import { defineStore } from 'pinia';
import { getCurrentInstance } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useLoadingStore } from '@/pinia/loading.module';
import { useAuthStore } from '@/pinia/auth.module';
import { getLocaleMessage } from '@/services/MessagesService';
import * as service from '@/services';

const $toast = useToast();

export const useBeneficiaryStore = defineStore('beneficiary', {
    persist: false,
    state: () => {
        return {
            beneficiaries: [],
            beneficiary: null,
            errors: [],
        }
    },
    getters: {
        getBeneficiaries: state => state.beneficiaries,
        getBeneficiary: state => state.beneficiary,
        filterBeneficiariesBySearchString: state => (searchString = '') => {
            if (!searchString) return state.beneficiaries;
            const lowerCaseSearchString = searchString.toLowerCase();
            return state.beneficiaries.filter(beneficiary => {
                
                //#region control de fecha string
                const instance = getCurrentInstance();
                const $filters = instance?.appContext.config.globalProperties.$filters;
                //#endregion control de fecha string

                const code = beneficiary.referenceNumber ? beneficiary.referenceNumber.toLowerCase() : '';
                const codeLoanApplication = beneficiary.loanApplication?.referenceNumber ? beneficiary.loanApplication?.referenceNumber.toLowerCase() : '';
                const firstName = beneficiary.generalInformation?.firstName ? beneficiary.generalInformation?.firstName.toLowerCase() : '';
                const lastName = beneficiary.generalInformation?.lastName ? beneficiary.generalInformation?.lastName.toLowerCase() : '';
                const mothersMaidenName = beneficiary.generalInformation?.mothersMaidenName ? beneficiary.generalInformation?.mothersMaidenName.toLowerCase() : '';

                const name = beneficiary.generalInformation?.businessName === null ? 
                        `${firstName} ${lastName} ${mothersMaidenName}`
                        :
                        beneficiary.generalInformation?.businessName ? beneficiary.generalInformation?.businessName.toLowerCase(): '';
                const email = beneficiary.generalInformation.email ? beneficiary.generalInformation.email.toLowerCase() : '';
                const homePhone = 
                    beneficiary.generalInformation?.officePhone === null ? 
                        beneficiary.generalInformation.homePhone ? beneficiary.generalInformation.homePhone.toLowerCase() : ''
                        :
                        beneficiary.generalInformation?.officePhone ? beneficiary.generalInformation?.officePhone.toLowerCase(): '';
                const status = beneficiary.status.name ? beneficiary.status.name.toLowerCase() : '';
                const createdAt = beneficiary.createdAt ? $filters?.formatDate(beneficiary.createdAt).toLowerCase() : '';
                const updatedAt = beneficiary.updatedAt ? $filters?.formatDate(beneficiary.updatedAt).toLowerCase() : '';

                return code.includes(lowerCaseSearchString) ||
                    codeLoanApplication.includes(lowerCaseSearchString) ||
                    status.includes(lowerCaseSearchString) ||
                    createdAt.includes(lowerCaseSearchString) ||
                    updatedAt.includes(lowerCaseSearchString) ||
                    name.includes(lowerCaseSearchString) ||
                    email.includes(lowerCaseSearchString) ||
                    homePhone.includes(lowerCaseSearchString);
            });
        }
    },
    actions: {
        setErrors(errors) {
            this.errors.push(errors);
        },
        updateBeneficiaryList(updatedBeneficiary) {
            const index = this.beneficiaries.findIndex(b => b.id === updatedBeneficiary.id);
            if (index !== -1) {
                this.beneficiaries.splice(index, 1, updatedBeneficiary);
            }
        },
        async fetchAllBeneficiariesByCompanyId() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/individual-beneficiaries/Company/${useAuthStore().user.companyId}/all`);
                if (data && data.status) {
                    this.beneficiaries = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async fetchBeneficiariesByCompanyId() {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.get(`/api/Beneficiaries/Company/${useAuthStore().user.companyId}`);
                if (data && data.status) {
                    this.beneficiaries = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async createIndividualBeneficiary(beneficiary) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.post('/api/individual-beneficiaries', beneficiary);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('beneficiaries', 'created'));
                    this.beneficiaries.push(data.data);
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        },
        async updateIndividualBeneficiary(id) {
            try {
                useLoadingStore().loading = true;
                const { data } = await service.prospectsApi.put(`/api/individual-beneficiaries/${id}`, beneficiary);
                if (data && data.status) {
                    $toast.success(getLocaleMessage('beneficiaries', 'updated'));
                    const index = this.beneficiaries.findIndex(b => b.id === id);
                    this.beneficiaries[index] = data.data;
                }
                useLoadingStore().loading = false;
            } catch (error) {
                useLoadingStore().loading = false;
                this.setErrors(error);

                if (error.response) {
                    // Error de respuesta desde la API (status code no 2xx)
                    this.setErrors(error.response.data);
                    $toast.error(getLocaleMessage('common', 'responseError'));
                } else if (error.request) {
                    // Error de red, no se recibió respuesta
                    $toast.error(getLocaleMessage('common', 'networkError'));
                } else {
                    // Otro tipo de error
                    $toast.error(getLocaleMessage('common', 'generalError'));
                }
            } finally {
                useLoadingStore().loading = false;
            }
        }
    }
})
