<template>
    <table class="table table-hover no-overflow">
        <thead>
            <tr>
                <th @click="sortBy('id')">
                    ID
                    <span v-if="sortKey === 'id'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th @click="sortBy('name')">
                    Nombre del rol
                    <span v-if="sortKey === 'name'">
                        <i :class="sortOrder === 1 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
                    </span>
                    <span v-else>
                        <i class="fas fa-sort"></i>
                    </span>
                </th>
                <th>Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in sortedData" :key="item.id" v-if="sortedData.length != 0">
                <td class="sorting_1 dtr-control"> {{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>
                    <PrimaryButton class="btn-success" data-toggle="modal" data-target="#modal-lg"
                        @click.prevent="editRole(item)"><i class="fa fa-edit"></i>
                    </PrimaryButton>
                    <PrimaryButton class="btn-danger" @click.prevent="deleteRole(item)"><i class="fa fa-trash"></i>
                    </PrimaryButton>
                </td>
            </tr>
            <tr v-else>
                <td colspan="5" style="text-align: center;">{{ $getLocaleMessage('common', 'noDataFound') }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import PrimaryButton from '@/Components/PrimaryButton.vue';

export default {
    components: { PrimaryButton },
    data() {
        return {
            sortKey: '',
            sortOrder: 1, // 1 for ascending, -1 for descending
        }
    },
    props: {
        list: {
            type: Array,
            default: () => { },
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: () => 10,
        },
        currentPage: {
            type: Number,
            default: () => 1
        }
    },
    computed: {
        getRoles() {
            return this.list;
        },
        sortedData() {
            let sortedList = [...this.list];
            if (this.sortKey) {
                sortedList.sort((a, b) => {

                    let aValue = this.getNestedValue(a, this.sortKey);
                    let bValue = this.getNestedValue(b, this.sortKey);


                    if (aValue < bValue) return -1 * this.sortOrder;
                    if (aValue > bValue) return 1 * this.sortOrder;
                    return 0;
                });
            }
            return this.paginate(sortedList);
        }
    },
    methods: {
        sortBy(key) {
            if (this.sortKey === key) {
                this.sortOrder *= -1; // Toggle the sort order
            } else {
                this.sortOrder = 1; // Reset to ascending if a new column is sorted
            }
            this.sortKey = key;
        },
        getNestedValue(obj, key) {
            return key.split('.').reduce((o, i) => o[i], obj);
        },
        paginate(list) {
            if (!list || list.length === 0) return [];
            let start = (this.currentPage * this.itemsPerPage) - this.itemsPerPage;
            return list.slice(start, start + this.itemsPerPage);
        },
        editRole(role) {
            this.$emit('edit-role', role);
        },
        deleteRole(role) {
            this.$emit('delete-role', role);
        }
    }
}
</script>